.navbar {
    margin-top:20px;
    margin-left:25px;
    left: 40px;
}

.menu-bars {
    margin-left: 10px;
    top:60px;
    font-size: inherit;
}
.nav-text a:hover {
}
.nav-menu.active {
    left: 0;
    top:0px;
    transition: 350ms;}


.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0 8px 16px;
    list-style: none;
    height: 40px;
}

.nav-text a {
    text-decoration: none;
    font-size: inherit;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}



.nav-menu-items {
    width: 100%;
    padding: 0;
}

.navbar-toggle {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
}

span {
    margin-left: 16px;
}

.topDeaths{
    caption-side: top;
}
