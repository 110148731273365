body {
    font-family: sans-serif;
    display: flex;
    margin: 0;
    padding: 0;
    /*flex-direction: ;*/
    /*overflow-x: auto;*/
    white-space: nowrap;
    /*color:#f37736;*/
    /*background-color: white;*/
}

.checkbox {
    margin: 10px;
}

svg {
    overflow: visible;
}

text {
    font-size: 13px;
}

.subtitle text {
    text-decoration: underline;
    font-size: 30px;
}

.Yxis {
}

.axis .domain,
.axis.xAxis line {
    display: none;
}


.baseGridLine line {
    stroke: rgba(255, 255, 255, 0.5);
}

.tooltip text {
    font-size: 12px;
}

.tooltipLine {
    stroke: green;
    stroke-width: 1px;
}

.tooltipContent .contentTitle {
    font-weight: bold;
}

.tooltipLinePoint {
    fill: #0392cf;
}


.tooltip-title {
    margin: 16px 5px 40px 5px;
}

.tooltip-value {
    margin: 16px 5px 8px 5px;
}

.tooltip-date {
    margin: 5px 5px 8px 5px;
}

.FinLaptop {

    flex: 1;
    margin-bottom: 20px;
}

.chart-containerLaptop {
    height: 400px;
    width: 1200px;
    padding: 10px;
    margin-top: 20px;
    flex: 1;
}

.title {
    text-align: center;
}

.tabs {
    list-style: none;
    display: flex;
    padding: 10px;

}

.tabs .tab.selected {
    border:  1px solid white;

}

.tabs .tab:hover {
    cursor: pointer;
    color: gray
}


.tabs .tab.selected:hover {
}

.tab__content {
}

.tab__icon {
    padding-left: 20px;
}

.subtitle {
}


.button:hover,
.button.hover {
    border: 1px solid gray;
    background: darkslateblue;
}